<template>
  <Layout>
    <saas-layout
      v-if="dataReady"
      :page-titles="pageTitle"
      :bread-crumb-items="breadcrumbItems"
      :connected="connected"
    >
      <template #connection-info>
        <saas-information
          :src="service.logo || ''"
          :connected="connected"
          :usage="service.category_name"
          :connect-func="activeConnection"
          :disconnect-func="showDisconnectConnection"
          :connected-date="connectedDate"
          :connected-total="usedApp"
        />
      </template>
      <template #connected-content>
        <div class="SaaSConnection">
          <div class="SaaSConnection__select-time">
            <select-time :month.sync="month" :year.sync="year" />
          </div>
          <div class="SaaSConnection__list-account">
            <list-account
              :dialog-state="dialogState"
              :reload-table.sync="reloadListAccountTable"
              :date="selectedDate"
              :fetch-data="fetchListUser"
              :show-delete-dialog="showRemoveDialog"
              :show-invite-dialog="showInviteDialog"
              :custom-field="dialogAccountFields"
            />
          </div>
          <div class="SaaSConnection__contract-info">
            <contract-info :selected-date="selectedDate" />
          </div>
          <div class="SaaSConnection__expense-info">
            <expense-infomation-table
              :refresh="refreshContractTable"
              :selected-date="selectedDate"
            />
          </div>
          <!-- saas remove accounts dialog -->
          <saas-service-modal
            :dialog-id="dialogRemoveId"
            :is-remove="true"
            :fields="dialogRemoveFields"
            :service-name="serviceName"
            :dialog-buttons="dialogRemoveButtons"
            :reset-flag="resetRemoveFlag"
            :reload-func="reloadRemove"
            :checked-values.sync="checkedValues"
            :check-all.sync="checkedAllRemoveUser"
            :search-value.sync="searchInputRemove"
          />
          <!-- saas invite accounts dialog -->
          <saas-invite-dialog
            :dialog-id="dialogInviteId"
            :is-invite="true"
            :reload="triggerReloadInvite"
            :reload-func="reloadInvite"
            :invite-func="showChooseOrgDialog"
          />
          <github-origin-modal
            :dialog-id="dialogChooseOrg"
            :logo="service.logo"
            :selected-org.sync="selectedOrg"
            :buttons="originButtons"
            :app-id="service.subscribe_apps_id"
          />
          <disconnect-saa-s-confirmation
            :id="disconnectDialogId"
            :confirm-disconnect-func="deactivateConnection"
          />
        </div>
      </template>
    </saas-layout>
  </Layout>
</template>
<script>
import Layout from '@/layouts/main/app'
import SaasLayout from '@/components/commons/common-saas-layout'
import SaasInformation from '@/components/saas/saas-connection-info'
import SaasServiceModal from '@/components/modals/saas-add-remove-modal'
import ListAccount from '@/components/tables/list-of-account-using-service-table'
import SelectTime from '@/components/saas/select-time'
import ContractInfo from '@/components/tables/contract-information-table'
import SaasInviteDialog from '@/components/modals/saas-invite-connect-modal'
import ExpenseInfomationTable from '@/components/tables/expense-infomation-table'
import { ALERT_TYPE } from '@/utils/constants'
import { githubService } from '@/services/saas-service/github-service'
import { saasCommonService } from '@/services/saas-service/saas-common-service'
import { mapActions, mapGetters } from 'vuex'
import { stringToDateJapan } from '@/utils/date-utils'
import { MESSAGES } from '@/utils/messages'
import DisconnectSaaSConfirmation from '@/components/modals/saas-disconnect-confirm-modal'
import { getParameterByName } from '@/utils/function-utils'
import GithubOriginModal from '@/components/modals/github-origin-modal'
export default {
  name: 'Github',
  components: {
    GithubOriginModal,
    DisconnectSaaSConfirmation,
    Layout,
    SaasLayout,
    SaasInformation,
    SaasServiceModal,
    ListAccount,
    SelectTime,
    ContractInfo,
    SaasInviteDialog,
    ExpenseInfomationTable,
  },
  data() {
    return {
      pageTitle: 'GITHUB',
      serviceName: 'GITHUB',
      breadcrumbItems: [
        {
          text: 'SaaS管理',
          href: '/customer/saas-management',
        },
        {
          text: '利用中のサービス',
          href: '/customer/saas-management',
        },
        {
          text: 'GITHUB',
          active: true,
        },
      ],
      service: {},
      serviceId: '',
      dialogInviteId: 'dialog-invite',
      dialogRemoveId: 'dialog-remove',
      disconnectDialogId: 'dialog-disconnect-confirm',
      dialogChooseOrg: 'dialog-choose-org',
      connected: false,
      dialogState: githubService.getDialogState(),
      dialogAccountFields: githubService.getSaaSAccountFields(),
      dialogRemoveFields: githubService.getSaaSRemoveDialogFields(),
      reloadRemove: saasCommonService.getListUserBySaas,
      reloadInvite: saasCommonService.getListIntegrateUsers,
      dataReady: false,
      month: '',
      year: '',
      resetRemoveFlag: false,
      checkedValues: [],
      checkedAllRemoveUser: false,
      searchInputRemove: '',
      reloadListAccountTable: false,
      refreshContractTable: false,
      triggerReloadInvite: false,
      originButtons: [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '連携',
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onSubmitInviteDialog,
        },
      ],
      selectedOrg: '',
      selectedUserId: '',
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['apps']),
    ...mapGetters('saas', ['code']),
    connectedDate() {
      return stringToDateJapan(this.service.connect_time)
    },
    selectedDate() {
      if (this.month && this.year) {
        return `${this.month}-${this.year}`
      }
      return ''
    },
    dialogRemoveButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '削除',
          variant: 'primary',
          minWidth: 124,
          size: 'md',
          func: this.onSubmitRemoveDialog,
        },
      ]
    },
    userIds() {
      return this.checkedValues.map((item) => item.id)
    },
    usedApp() {
      return this.$store.getters['saas/service'].member || 0
    },
  },
  async created() {
    this.serviceId = Number(this.$route.fullPath.split('/').pop())
    this.service = await saasCommonService.getDetailSaas(this.serviceId)
    this.setService(this.service)
    this.savedMetadata = await saasCommonService.getSavedMetadata(this.serviceId)
    this.setServiceMetadata(this.savedMetadata)
    this.connected = this.service.connect_time && this.service.is_active
    if (!this.connected) {
      this.resetFetchTime(this.serviceId)
    }
    this.dataReady = true
    if (!this.service) {
      await this.$router.push('/404')
    }
  },
  async mounted() {
    if (this.code) {
      this.authorizedCode = this.code
      this.resetState() // reset code received from external service
      this.resetFetchTime(this.serviceId)
    }
    if (this.authorizedCode) {
      await this.handleConnectGithub()
    }
  },
  methods: {
    ...mapActions('saas', [
      'setCallbackRoute',
      'resetState',
      'setService',
      'setServiceMetadata',
      'resetFetchTime',
    ]),
    ...mapActions('alert', ['displayAlert']),
    ...mapActions('loading', ['showManualLoading', 'hideManualLoading', 'enableLoading']),
    activeConnection() {
      // save current path
      this.setCallbackRoute(this.$route.fullPath)
      // get code from Github
      window.open(
        `${process.env.VUE_APP_GITHUB_AUTHORIZE_URL}${process.env.VUE_APP_URL}/authorize`,
        '_self'
      )
      // call api,auth
    },
    async handleConnectGithub() {
      // gen access token
      const postData = {
        client_id: process.env.VUE_APP_GITHUB_CLIENT_ID,
        client_secret: process.env.VUE_APP_GITHUB_CLIENT_SECRET,
        code: this.authorizedCode,
        grant_type: 'authorization_code',
        redirect_uri: `${process.env.VUE_APP_URL}/authorize`,
      }
      this.enableLoading()
      this.showManualLoading()
      try {
        const result = await githubService.getAccessToken(postData)
        if (result) {
          const metaData = {
            access_token: getParameterByName(result, 'access_token'),
            instance_url: process.env.VUE_APP_GITHUB_INSTANCE_URL,
            token_type: 'Bearer',
            auth_type: 'Authorization',
          }
          const res = await saasCommonService.connectApp(this.serviceId)
          if (!res.success) {
            this.displayAlert({
              type: ALERT_TYPE.ERROR,
              messages: res.messages || '',
            })
            return
          }
          await saasCommonService.addToken(this.service.id, metaData, true)
          this.service = await saasCommonService.getDetailSaas(this.service.id, true)
          this.setService(this.service)
          this.savedMetadata = await saasCommonService.getSavedMetadata(this.service.id, true)
          this.setServiceMetadata(this.savedMetadata)
          this.connected = true
          this.displayAlert({
            type: ALERT_TYPE.SUCCESS,
            messages: MESSAGES.SAAS_CONNECT.LK01,
          })
        } else {
          this.displayAlert({
            type: ALERT_TYPE.ERROR,
            messages: MESSAGES.COMMON.MSG15,
          })
        }
        this.hideManualLoading()
      } catch (e) {
        this.hideManualLoading()
      } finally {
        this.hideManualLoading()
      }
    },
    showDisconnectConnection() {
      this.$bvModal.show(this.disconnectDialogId)
    },
    async deactivateConnection() {
      // call api,auth
      const { success, message } = await saasCommonService.disconnectApp(this.service.id)
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: message,
        })
        return
      }
      this.connected = false
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.SAAS_CONNECT.LK03,
      })
    },
    showInviteDialog() {
      this.$bvModal.show(this.dialogInviteId)
      this.triggerReloadInvite = !this.triggerReloadInvite
    },
    showChooseOrgDialog(userId) {
      this.selectedUserId = userId
      this.$bvModal.show(this.dialogChooseOrg)
    },
    async onSubmitInviteDialog() {
      const result = await githubService.inviteUser(
        this.service.subscribe_apps_id,
        this.selectedUserId,
        this.selectedOrg
      )
      if (result) {
        this.displayAlert({
          type: ALERT_TYPE.SUCCESS,
          messages: MESSAGES.SAAS_CONNECT.LK07,
        })
      }
      this.$bvModal.hide(this.dialogChooseOrg)
      this.$bvModal.hide(this.dialogInviteId)
    },
    showRemoveDialog() {
      this.resetRemoveFlag = !this.resetRemoveFlag
      this.$bvModal.show(this.dialogRemoveId)
    },
    async onSubmitRemoveDialog() {
      if (this.userIds.length === 0) {
        return
      }
      let ids = this.userIds
      if (this.checkedAllRemoveUser) {
        const { items } = await saasCommonService.getListUserBySaas(
          this.serviceId,
          null,
          null,
          null,
          null,
          this.searchInputRemove
        )
        ids = items.map((item) => item.id)
      }
      // call api remove
      const { success, message } = await githubService.deleteUser(
        this.service.subscribe_apps_id,
        ids
      )
      if (!success) {
        // delete success
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: message,
        })
        return
      }
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG05,
      })
      this.reloadListAccountTable = !this.reloadListAccountTable
      this.$bvModal.hide(this.dialogRemoveId)
    },
    async fetchListUser() {
      const { success, message } = await githubService.fetchListUser(this.service.subscribe_apps_id)
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: message,
        })
        return
      }
      this.refreshContractTable = !this.refreshContractTable
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.SAAS_CONNECT.LK04,
      })
    },
  },
}
</script>
