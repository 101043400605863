<template>
  <div>
    <common-modal :id="dialogId" title="オーガニゼイション選択" size="lg" :buttons="buttons">
      <div class="GithubOrigin">
        <b-form>
          <div class="GithubOrigin__logo">
            <img :src="logo || defaultCompanyLogo" alt="Github-logo" />
          </div>
          <div class="GithubOrigin__form">
            <b-form-group
              id="select-group"
              class="mb-3"
              label="オーガニゼイション"
              label-for="select-1"
            >
              <b-form-select
                id="select-1"
                v-model="orgData"
                class="form-select"
                :options="originOptions"
                type="text"
              />
            </b-form-group>
          </div>
        </b-form>
      </div>
    </common-modal>
  </div>
</template>

<script>
import CommonModal from '@/components/commons/common-modal'
import { DEFAULT_COMPANY_LOGO } from '@/utils/constants'
import { githubService } from '@/services/saas-service/github-service'
export default {
  name: 'GithubOriginModal',
  components: { CommonModal },
  props: {
    dialogId: {
      type: String,
      required: true,
    },
    buttons: {
      type: Array,
      default: null,
    },
    logo: {
      type: String,
      default: '',
    },
    selectedOrg: {
      type: String,
      default: '',
    },
    appId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      defaultCompanyLogo: DEFAULT_COMPANY_LOGO,
      originOptions: [],
      orgData: '',
    }
  },
  watch: {
    orgData(newValue) {
      this.$emit('update:selectedOrg', newValue)
    },
  },
  async created() {
    const data = (await githubService.getOrigins(this.appId)).data
    this.originOptions = data.map((item) => ({
      value: item.org,
      text: item.org,
    }))
    if (this.originOptions.length) {
      this.orgData = this.originOptions[0].value
    }
  },
}
</script>

<style lang="scss">
.GithubOrigin {
  &__logo {
    margin: 20px auto;
    height: 70px;
    width: 70px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
