import axios from 'axios'

const externalApiAxios = axios.create({
  timeout: 10000,
  withCredentials: false,
})

externalApiAxios.interceptors.request.use(
  (config) => {
    // remove content-type
    config.transformRequest = [
      function (data, headers) {
        delete headers.common['Content-Type']
        return data
      },
    ]
    return config
  },
  (error) => {
    Promise.reject(error).then()
  }
)
export default externalApiAxios
