<template>
  <c-modal
    :id="dialogId"
    :title="dialogTitle"
    :buttons="dialogButtons"
    dialog-class="SaasLargeModal"
    size="xl"
  >
    <div class="Table">
      <!--  table toolbar  -->
      <div class="Table__toolbar">
        <div class="Table__toolbar-search">
          <search-input :value.sync="searchInput" />
        </div>
      </div>
      <!--    table content  -->
      <div class="table-responsive">
        <b-table
          :items="items"
          :fields="fields"
          sort-by="external_id"
          thead-class="Table__header"
          tbody-tr-class="Table__row"
          hover
        >
          <template #head()="data">
            <div v-if="!!data.label" class="header">
              <div class="header--value">
                <span>
                  {{ data.label }}
                </span>
              </div>
              <div v-if="data.field.sortable" class="header--sort">
                <SortIcon />
              </div>
            </div>
            <div v-else class="checkbox">
              <b-form-checkbox
                v-model="statusCheckAll"
                class="text-center"
                @change="handleCheckAll"
              />
            </div>
          </template>
          <template #cell(checkbox)="data">
            <b-form-checkbox
              v-if="!disableRule(data.item)"
              v-model="statusArrs[data.item.id]"
              class="text-center"
            />
            <b-form-checkbox v-else class="text-center" disabled />
          </template>
          <template v-if="isCreate" #cell(status)="data">
            <div :class="getClassStatus(data.item.status)">
              {{ getStatusLabel(data.item.status) }}
            </div>
          </template>
          <template v-else #cell(integrate_info_status)="data">
            <div
              v-if="data.item.integrate === INTEGRATE_STATUS.NOT_CONNECTED"
              class="text-center cell__status cell__status-warning"
            >
              {{ NOT_LINKING_SAAS }}
            </div>
            <div v-else class="text-center" :class="getClassRemoveStatus(data.item)">
              {{ getStatusRemoveLabel(data.item) }}
            </div>
          </template>
          <template #cell(integrate_info_user_code)="data">
            {{ data.value ? data.value : DISABLE_LABEL }}
          </template>

          <template v-if="isRemove" #cell(integrate_info_name)="data">
            <span v-if="data.item.integrate === INTEGRATE_STATUS.NOT_CONNECTED">{{
              DISABLE_LABEL
            }}</span>
            <div class="text-info detail-info">
              <span
                v-if="
                  data.item.integrate === INTEGRATE_STATUS.AUTO_CONNECTED ||
                  data.item.integrate === INTEGRATE_STATUS.MANUAL_CONNECTED
                "
                @click="showDetailDialog(data.item['integrate_info_id'])"
              >
                {{ data.value }}
              </span>
            </div>
          </template>

          <template #cell(integrate_info_department_name)="data">
            {{ data.value ? data.value : DISABLE_LABEL }}
          </template>
          <template v-if="isRemove" #cell(linking)="data">
            <div>{{ getLinkingStatus(data.item.sync) }}</div>
          </template>
          <template #cell(created_at)="data">
            <div>
              {{ getDateFromString(data.value) }}
            </div>
          </template>
        </b-table>
      </div>
      <!--   table paging   -->
      <div class="Table__paging">
        <table-paging
          :current-page.sync="currentPage"
          :per-page.sync="perPage"
          :options-paging="optionsPaging"
          :total="total"
        />
      </div>
    </div>
  </c-modal>
</template>
<script>
import CModal from '@/components/commons/common-modal'
import SearchInput from '@/components/commons/common-search-input'
import TablePaging from '@/components/commons/common-table-paging'
import SortIcon from '@/components/sort-icon'
import { mapGetters } from 'vuex'
import {
  INTEGRATE_STATUS,
  MEMBER_STATUS,
  NOT_LINKING_SAAS,
  SAAS_CONNECTION,
  SYNC_STATUS,
  USER_STATUS,
} from '@/utils/constants'
import { arrayUniqueByKey, handleChangeInput } from '@/utils/function-utils'
import { stringToDateJapan } from '@/utils/date-utils'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'
import { flattenArray, isNotNullOrUndefined } from '@/utils/object-helpers'

export default {
  name: 'SaasAddRemoveModal',
  components: { CModal, SearchInput, TablePaging, SortIcon },
  props: {
    dialogId: {
      type: String,
      required: true,
    },
    isRemove: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
    serviceName: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      default: () => {
        return []
      },
    },
    dialogButtons: {
      type: Array,
      default: () => {
        return []
      },
    },
    resetFlag: {
      type: Boolean,
      default: false,
    },
    reloadFunc: {
      type: Function,
      default: () => {
        return {}
      },
    },
    checkedValues: {
      type: Array,
      default: () => {
        return []
      },
    },
    checkAll: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: String,
      default: '',
    },
    disableRule: {
      type: Function,
      default: () => {
        return false
      },
    },
  },
  data() {
    return {
      searchInput: '',
      items: [],
      total: 0,
      currentPage: 1,
      perPage: 10,
      optionsPaging: [10, 50, 100],
      statusCheckAll: false,
      statusCheckAllManual: false,
      statusArrs: {},
      INTEGRATE_STATUS: INTEGRATE_STATUS,
      DISABLE_LABEL: SAAS_CONNECTION.DISABLE_LABEL,
      NOT_LINKING_SAAS: NOT_LINKING_SAAS,
    }
  },
  computed: {
    ...mapGetters('saas', ['service']),
    ...mapGetters('commonSettings', ['user_status']),
    dialogTitle() {
      if (this.isRemove) {
        return 'アカウント削除'
      }
      if (this.isCreate) {
        return 'サービスにアカウント新規登録'
      }
      return `従業員を${this.serviceName}利用中のアカウント一覧に追加（自己入力）`
    },
  },
  watch: {
    searchInput(newVal) {
      this.reloadData()
      this.$emit('update:searchValue', newVal)
    },
    async perPage() {
      await this.pagingReload()
    },
    async currentPage() {
      await this.pagingReload()
    },
    async resetFlag() {
      // reset checkbox value
      this.statusArrs = {}
      this.currentPage = 1
      this.perPage = 10
      await this.reloadData()
    },
    statusArrs: {
      handler: function (newVal) {
        if (isEmpty(newVal)) {
          this.$emit('update:checkedValues', [])
          this.statusCheckAll = false
          this.$emit('update:checkAll', false)
          return
        }
        const checkedItems = this.getCheckedItems(newVal)
        if (checkedItems.length > 0 && checkedItems.length === this.total) {
          this.statusCheckAll = true
        } else {
          const checkedItemsCurrentPage = this.getCheckedItemsCurrentPage(newVal)
          // statusCheckAllManual : check checkbox checkAll manually
          // if currentPage === 1, no need to check the manual check all
          this.statusCheckAll =
            ((this.currentPage === 1 && this.perPage > this.total) || this.statusCheckAllManual) &&
            checkedItemsCurrentPage.length > 0 &&
            (checkedItemsCurrentPage.length ===
              this.total - (this.currentPage - 1) * this.perPage ||
              checkedItemsCurrentPage.length === this.perPage)
        }
        if (!this.statusCheckAll) {
          this.statusCheckAllManual = this.statusCheckAll
          this.$emit('update:checkAll', false)
        }
        this.$emit('update:checkedValues', checkedItems)
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    await this.reloadData()
  },
  methods: {
    getStatusLabel(id) {
      return this.user_status.find((item) => item.id === id).name
    },
    getClassStatus(id) {
      if (id === USER_STATUS.ACTIVE) {
        return 'cell__status cell__status-active'
      }
      return 'cell__status cell__status-inactive'
    },
    async reloadData() {
      if (this.service.subscribe_apps_id) {
        if (this.isCreate) {
          const { items, total } = await this.reloadFunc(
            this.service.subscribe_apps_id,
            1, // backend not using this parameter, just a random number
            this.currentPage,
            this.perPage,
            this.searchInput
          )
          this.items = items
          this.total = total
        } else {
          const { items, total } = await this.reloadFunc(
            this.service.id,
            null,
            null,
            this.currentPage,
            this.perPage,
            this.searchInput
          )
          this.items = flattenArray(items)
          this.total = total
        }
      }
    },
    getDateFromString(value) {
      return stringToDateJapan(value)
    },
    showDetailDialog(employeeId) {
      this.$router.push({
        path: '/customer/employee-detail',
        name: 'employee-detail',
        params: {
          id: employeeId,
        },
      })
    },
    handleCheckAll(checked) {
      this.$emit('update:checkAll', checked)
      if (!checked) {
        this.statusCheckAllManual = false
        this.statusArrs = {}
      } else {
        this.statusCheckAllManual = true
        this.manualCheckAll(this.items)
      }
    },
    manualCheckAll(items) {
      const objs = {}
      items.forEach((item) => {
        objs[item.id] = true
      })
      this.statusArrs = { ...this.statusArrs, ...objs }
    },
    getCheckedItems(key) {
      const checkedArrs = []
      const notCheckedArrs = []
      Object.keys(key).forEach((k) => {
        if (key[k]) {
          checkedArrs.push(Number(k))
        } else {
          notCheckedArrs.push(Number(k))
        }
      })
      return arrayUniqueByKey(
        [
          ...this.items.filter((item) => {
            return checkedArrs.includes(item.id)
          }),
          ...this.checkedValues.filter((item) => {
            return !notCheckedArrs.includes(item.id)
          }),
        ],
        'id'
      )
    },
    getCheckedItemsCurrentPage(key) {
      const checkedArrs = []
      Object.keys(key).forEach((k) => {
        if (key[k]) {
          checkedArrs.push(Number(k))
        }
      })
      return this.items.filter((item) => {
        return checkedArrs.includes(item.id)
      })
    },
    getLinkingStatus(linkId) {
      return SYNC_STATUS.find((item) => item.id === linkId).text
    },
    getClassRemoveStatus(item) {
      if (item['integrate_info_status'] === MEMBER_STATUS.ACTIVE) {
        return 'cell__status cell__status-active'
      }
      return 'cell__status cell__status-inactive'
    },
    getStatusRemoveLabel(rowData) {
      if (isNotNullOrUndefined(rowData.integrate_info_status)) {
        const status = get(rowData, 'integrate_info_status')
        return this.user_status.find((item) => item.id === status).name
      }
    },
    async pagingReload() {
      await this.reloadData()
      if (this.statusCheckAll || this.statusCheckAllManual) {
        this.manualCheckAll(this.items)
      }
    },
  },
}
</script>
