import axiosEx from '@/configs/http/external-api-axios'
import { convertToFormUrlencodedData } from '@/utils/object-helpers'
import axiosIn from '@/configs/http/internal-api-axios'
import { CORS_EVERY_WHERE_URL, STATUS_CODE } from '@/utils/constants'
import get from 'lodash-es/get'
import { MESSAGES } from '@/utils/messages'

function getDialogState() {
  /*
  true function can be used
  */
  return {
    ADD: false,
    CREATE: false,
    INVITE: true,
    DELETE: true,
    FETCH: true,
  }
}

async function getAccessToken(formData) {
  const url =
    CORS_EVERY_WHERE_URL + process.env.VUE_APP_GITHUB_OAUTH_URL ||
    'https://github.com/login/oauth/access_token'
  const response = await axiosEx
    .post(url, convertToFormUrlencodedData(formData))
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err
    })
  return response.data
}

async function fetchListUser(subscribe_apps_id) {
  if (!subscribe_apps_id) {
    return
  }
  const url = `api/v1/service/github/${subscribe_apps_id}`
  const response = await axiosIn
    .get(url)
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err
    })
  return response.data
}

async function deleteUser(subscribe_apps_id, userIds) {
  if (!subscribe_apps_id) {
    return
  }
  const url = `api/v1/service/github/${subscribe_apps_id}/remove`
  return axiosIn
    .post(url, { user_id: userIds.toString() })
    .then((res) => {
      const errorMessage = get(res.data, 'error', '')
      const statusCode = get(res.data, 'statusCode', '')
      if (errorMessage && statusCode === STATUS_CODE.FORBIDDEN) {
        return handleError(errorMessage)
      }
      return res.data
    })
    .catch((err) => {
      return err
    })
}

async function inviteUser(subscribe_apps_id, userIds, org) {
  if (!subscribe_apps_id) {
    return
  }
  const url = `api/v1/service/github/${subscribe_apps_id}/invite`
  const response = await axiosIn
    .post(url, { user_id: userIds, org: org })
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err
    })
  return response.data.success
}

async function getOrigins(subscribe_apps_id) {
  if (!subscribe_apps_id) {
    return
  }
  const url = `api/v1/service/github/${subscribe_apps_id}/orgs`
  const response = await axiosIn
    .get(url)
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err
    })
  return response.data
}

function getSaaSAccountFields() {
  return [
    { key: 'external_id', label: 'アカウントID', sortable: true },
    { key: 'github_org', label: 'オーガニゼイション', sortable: true },
    { key: 'name', label: 'ユーザー名', sortable: true },
    { key: 'email', label: 'メールアドレス', sortable: true },
    { key: 'integrate_info_user_code', label: '従業員ID', sortable: true },
    { key: 'integrate_info_name', label: '従業員', sortable: true },
    { key: 'integrate_info_department_name', label: '部署', sortable: true },
    { key: 'created_at', label: '連携日時', sortable: true },
    { key: 'linking', label: '連携方法', sortable: true },
    { key: 'integrate_info_status', label: 'ステータス', sortable: true },
  ]
}

function getSaaSRemoveDialogFields() {
  return [
    { key: 'checkbox', label: '', sortable: false },
    { key: 'external_id', label: 'アカウントID', sortable: true },
    { key: 'github_org', label: 'オーガニゼイション', sortable: true },
    { key: 'name', label: 'ユーザー名', sortable: true },
    { key: 'email', label: 'メールアドレス', sortable: true },
    { key: 'integrate_info_user_code', label: '従業員ID', sortable: true },
    { key: 'integrate_info_name', label: '従業員', sortable: true },
    { key: 'integrate_info_department_name', label: '部署', sortable: true },
    { key: 'created_at', label: '連携日時', sortable: true },
    { key: 'linking', label: '連携方法', sortable: true },
    { key: 'integrate_info_status', label: 'ステータス', sortable: true },
  ]
}

function handleError(errorMessages) {
  const result = {
    success: false,
    message: '',
  }
  if (errorMessages.indexOf('Cannot remove the last owner') !== -1) {
    result.message = MESSAGES.SAAS_CONNECT.LK11
  }
  return result
}

export const githubService = {
  getAccessToken,
  fetchListUser,
  getDialogState,
  deleteUser,
  inviteUser,
  getOrigins,
  getSaaSAccountFields,
  getSaaSRemoveDialogFields,
}
